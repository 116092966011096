<template>
  <a-dims-search-bar hide-search>
    <div class="flex justify-between p-4 space-x-2 -mx-4">
      <button @click="$router.back" class="space-x-2 text-gray-700 uppercase flex items-center">
        <i class="icon-arrowLeft text-gray-600" />
        <span class="font-bold">Atrás</span>
      </button>
    </div>
  </a-dims-search-bar>

  <div class="mt-20">
    <Logo class="block mx-auto self-center text-left mt-6 w-64 h-16 cursor-pointer" />

    <div v-if="!loading">
      <div class="block text-center text-onboarding_gray my-4">
        <h2 class="inline-block w-auto text-SFRed text-xxl">Solo <span class="">{{ mappedPrice }}</span></h2>
        <div class="h-6">
          <p v-if="selectedPlan.duration_in_days === 365" class="text-SFRed text-lg">*contrato anual</p>
        </div>
      </div>

      <div class="wrapper block mx-auto mx-4 sm:w-6/12">
        <div v-for="plan in plans.visible_prices" :key="plan" class="m-4">
          <button
            @click="selectPlan(plan)"
            class="flex option option-1 border border-onboarding_gray p-normal rounded-md w-full"
            :class="{
                'selected-plan': selectedPlan === plan
            }"
          >
          <span class="flex-auto">
            <span class="title block text-xl font-bold">{{ plan.name }}</span>
            <span class="text block text-lg">{{ plan.description }}</span>
          </span>
            <span class="flex-none self-center ml-2">
            <button
              class="rounded-full border-white px-4 py-small text-white bg-onboarding_gray font-medium"
              :class="{
                    'selected-button': selectedPlan === plan
                }"
            >
            {{ selectedPlan === plan ? 'Elegido' : 'Elegir'}}
            </button>
          </span>
          </button>
        </div>
        <div class="mt-12">
          <button
            @click="planModal = true"
            class="bg-primary rounded-full text-white px-6 py-2 disabled:bg-gray"
            :disabled="selectedPlan?.id === actualPlan?.plan_price_id">Cambiar plan</button>
        </div>
      </div>
    </div>
  </div>
  <a-alert
    loader
    :loader-style="'h-4 w-4'"
    :show="planModal"
    :loading="loading"
    @confirm="changePlan"
    @cancel="planModal = false"
  >
    <p class="block mx-auto font-bold text-xl text-center">¿Seguro que desea cambiar de plan?</p>
    <div class="flex gap-x-2 mt-4">
      <div class="flex-1 py-6 border border-onboarding_gray rounded-lg">
        <p class="block text-center">Actual</p>
        <p class="block text-center font-bold text-lg">{{ actualPlan.price_name }}</p>
      </div>
      <div class="flex-1 py-6 selected-plan rounded-lg">
        <p class="block text-center">Nuevo</p>
        <p class="block text-center font-bold text-lg">{{ selectedPlan.name }}</p>
      </div>
    </div>
<!--    <p class="block mt-4">Se cambiara su plan actual: <span class="font-bold">{{ actualPlan.price_name }}</span> por</p>-->
<!--    <p class="block">{{ selectedPlan.name }}</p>-->
  </a-alert>
  <a-alert
    :show="fail || success"
    @confirm="$router.go(-1)"
    hide-cancel
  >
    <div v-if="success" >
      <h2 class="block text-lg text-center text-green-600">¡Enhorabuena!</h2>
      <p class="mt-4 text-center block">Se ha cambiado el plan con exito.</p>
    </div>
    <div v-else-if="fail">
      <h2 class="block text-lg text-center text-red-400">¡Fallido!</h2>
      <p class="mt-4 text-center block">No se pudo realizar el cambio de plan</p>
    </div>
  </a-alert>
</template>

<script>

import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useRepository } from '../../../hooks'
import Logo from '@/components/logos/logo-1'

export default {
  name: 'edit-plan',
  components: {
    Logo
  },
  setup () {
    const actualPlan = ref([])
    const selectedPlan = ref([])
    const plans = ref([])
    const route = useRoute()
    const loading = ref(false)
    const description = ref('')
    const planModal = ref(false)
    const fail = ref(false)
    const success = ref(false)
    const userRepository = useRepository(({ users }) => users)
    const stripeRepository = useRepository(({ stripe }) => stripe)

    const selectPlan = (plan) => {
      selectedPlan.value = plan
      // emit('update:plan', selectedPlan.value)
    }

    const mappedPrice = computed(() => {
      if (selectedPlan.value.duration_in_days === 365) {
        const price = (selectedPlan.value.price_e2 / 100) / 12
        return price.toFixed(2) + '€/mes'
      }
      // selectedPlan.value
      return selectedPlan.value.description
    })

    const changePlan = () => {
      loading.value = true
      const body = {
        plan_id: selectedPlan.value.plan_id,
        plan_price_id: selectedPlan.value.id
      }
      stripeRepository.swapPlan(body)
        .then(() => {
          success.value = true
        })
        .catch(() => {
          fail.value = true
        })
        .finally(() => {
          planModal.value = false
          loading.value = false
        })
    }

    const getPlans = () => {
      loading.value = true
      stripeRepository.plans()
        .default()
        .then(({ data: response }) => {
          plans.value = response
          description.value = plans.value.visible_prices[0].description
          plans.value.visible_prices.forEach((plan) => {
            if (plan.id === actualPlan.value.plan_price_id) {
              selectedPlan.value = plan
            }
          })
          // selectedPlan.value = plans.value.visible_prices[0]
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      loading.value = true
      userRepository.show(route.params.userId)
        .then(({ data: response }) => {
          actualPlan.value = response.current_plan
          // console.log(actualPlan.value)
        })
        .catch(() => {
          loading.value = false
        })
        .then(() => {
          getPlans()
          // stripeRepository.plans()
          //   .default()
          //   .then(({ data: response }) => {
          //     plans.value = response
          //     // plans.value.filter((plan) => {
          //     //   console.log(plan)
          //     //   return plan.id === actualPlan.value.plan_id
          //     // })
          //   })
        })
    })

    return {
      actualPlan,
      plans,
      selectedPlan,
      description,
      loading,
      mappedPrice,
      planModal,
      fail,
      success,
      selectPlan,
      changePlan
    }
  }
}
</script>

<style scoped>
.selected-plan {
  @apply bg-onboarding_gray text-white
}
.selected-button {
  background-color: #fff;
  font-weight: 500;
  color: #1D252C;
  border: none;
}
.wrapper .option {
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

input[type="radio"] {
  display: none;
}

#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  /*border-color: #cf1a1a;*/
  background-color: #fff;
  color: #1D252C;
}

#option-1:checked:checked ~ .option-1 button,
#option-2:checked:checked ~ .option-2 button {
  background-color: #1D252C;
  font-weight: 500;
  color: #fff;
  border: none;
}
</style>
